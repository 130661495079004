.category-content {
  display: flex;
  flex-wrap: wrap;
}

.category {
  display: flex;
  align-items: center;
  min-height: 44px;
  width: fit-content;
  padding: 8px 10px 8px 16px;
  margin: 20px 4px 20px 20px;
  border-radius: 8px;
  border: 1px solid $brandViolet;
  justify-content: space-between;

  &_name {
    font-weight: 400;
    font-size: 16px;
    color: $brandDark;
    transition: $transition-base;

    &:hover {
      color: $brandViolet;
    }
  }

  &_btn {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    transition: all 0.3s;
    border-radius: 100%;
    padding: 2px;
    margin-left: 5px;

    &:hover {
      background-color: #5f5f6121;
    }
  }
}

.addcategory-section {
  display: flex;
  justify-content: space-between;
  margin: 20px 20px 0 20px;

  &_btn {
    border: none;
    border-radius: 8px;
    padding: 8px 24px;
    font-weight: 500;
    line-height: 16px;
    height: 50px;
    margin: auto 0 auto 10px;
    transition: $transition-base;
  }
}

.add-category-form {
  width: 80%;
}

.brand_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px rgba(74, 85, 104, 0.2);
  border-radius: 36px;
  margin: 0 10px 0 7px;
  width: 60px;
  height: 60px;

  &-img {
    width: 44px;
    height: 30px;
    overflow: hidden;
    object-fit: contain;
  }
}

.brand_image {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 3px rgba(74, 85, 104, 0.2);
  margin: 0 10px 0 7px;
  aspect-ratio: 390 / 240;
  width: 100%;

  &-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }
}

.inactive {
  background: #e9ecef;
  width: 60px;
  border-radius: 36px;
  height: 60px;
}

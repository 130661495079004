.coupon {
  width: 342px;
  max-height: fit-content;
  border: 1px solid $brandViolet;
  border-radius: 16px;
  min-height: 295px;
  margin: 20px;

  &_container {
    width: 100%;
    height: 211px;
    overflow: hidden;
    padding: 7px;
    align-items: center;

    &-img {
      border-radius: 12px;
      width: 100%;
      height: 100%;
      // object-fit: contain;
      object-position: center;
    }
  }

  &_section {
    display: flex;
    max-width: 100%;
    height: 81px;
    padding: 15px 14px 15px 18px;
    align-items: center;
    justify-content: space-between;
  }

  &_desc {
    font-weight: 400;
    font-size: 16px;
    color: #1b1b1d;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &_navigation {
    display: flex;
    align-items: center;
  }

  &_logo {
    box-shadow: 0px 0px 3px rgba(74, 85, 104, 0.2);
    border-radius: 36px;
    padding: 12px 6px;
    margin: 0 10px 0 7px;

    &-img {
      width: 38px;
      height: 26px;
      overflow: hidden;
      object-fit: contain;
    }
  }

  &_btn {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  &_preview-header {
    font-weight: 900;
    font-size: 16px;
    color: $brandDark;
    margin: 20px;
  }
}

.dropdown-menu {
  border-radius: 8px;
}

a {
  color: $brandDark !important;
  transition: $transition-base;

  &:hover {
    color: $brandViolet !important;
  }
}

.addcoupon-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;

  &_form {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 40px;
    width: 70%;
    border-radius: 8px;
  }

  &_realtime {
    background-color: $white;
    padding: 40px 20px;
    border-radius: 8px;
    margin-left: 15px;
  }
}

.empty_data {
  display: flex;
  width: 100%;
  justify-content: center;
}

.date-control {
  border: 1px solid #eeeeee !important;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px !important;
  color: #1b1b1d !important;
  line-height: 31px;
  width: 100%;

  &_span {
    display: flex;
    margin: auto 10px;
    color: $brandDark;
    font-weight: 400;
    font-size: 16px;
  }
}

.switch_couponsflow {
  height: 50px;
  max-width: 510px;
  border-radius: 4px;
  border: 1px solid $brandViolet;
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.16);
  padding: 1px 0 1px 4px;
  margin-bottom: 40px;
}

.coupon-btn {
  padding: 16px 32px;
  border: none;
  font-weight: 500;
  font-size: 19px;
  border-radius: 8px;
  margin-right: 16px;
  min-width: 174px;
  transition: $transition-base;

  &:last-child {
    margin-right: 0;
  }
}

.approve {
  background-color: $brandViolet;
  color: $white;

  &:hover {
    background-color: $brandVioletHover;
  }
}

.reject {
  background-color: transparent;
  border: 1px solid $brandDark !important;
  color: $brandDark;

  &:hover {
    border-color: $brandViolet;
    color: $brandViolet;
  }
}

.chart {
  &_header {
    font-weight: 900;
    font-size: 23px;
    color: $brandDark;
    margin-bottom: 40px;

    &-info {
      display: flex;
      width: 50%;
      align-items: center;
      margin-right: 10px;

      &_title {
        font-weight: 900;
        font-size: 28px;
        line-height: 38px;
        color: $brandDark;
      }

      &_stat {
        display: flex;
        align-items: center;
        width: 50%;
        margin-left: 24px;
      }

      &_percent {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #04ce00;
        margin-left: 4px;
      }

      &_span {
        display: flex;
        font-weight: 400;
        font-size: 14px;
        // height: 38px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        margin-left: 4px;
      }
    }
  }
}

@import "../../bootstrap/variables";

.header {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.header_group {
  display: flex;
  cursor: pointer;
}

.header_group_item {
  margin-right: 8px;
  color: $brandDark !important;
  transition: $transition-base;

  &:hover {
    color: $brandViolet !important;
  }

  &:last-child {
    margin-right: unset;
    color: $categoriescolor !important;
  }
}

.dropdown-menu_item {
  &:hover {
    color: $brandViolet !important;
  }
}

.navbar-list {
  align-items: center;
}

.nav-link {
  &:hover {
    color: $brandViolet !important;
  }
}

.changeable:first-letter {
  text-transform: uppercase;
}

.recommended {
  display: flex;
  flex-wrap: wrap;
}

.addrecommended {
  //   display: flex;
  //   flex-direction: column;
  //   width: 100%;

  &_form {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 40px 20px;
    align-items: center;

    &_btn {
      border: none;
      border-radius: 8px;
      padding: 8px 24px;
      font-weight: 500;
      line-height: 16px;
      height: 50px;
      margin: auto 0 auto 10px;
      transition: $transition-base;
    }
  }

  &_span {
    margin-left: 20px;
    font-weight: 500;
    font-size: 16px;
    color: $brandDark;
  }

  &_coupons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.history_item {
  display: flex;
  flex-direction: column;

  &-span {
    margin: 0 0 20px 20px;
    font-weight: 400;
    font-size: 14px;
    color: $brandDark;
    line-height: 19px;
  }
}

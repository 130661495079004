@import "../../bootstrap/variables";

.footer {
  padding: 3px 0;
}

.footer_item {
  color: $brandDark !important;
  margin-right: 24px !important;
  transition: $transition-base;

  &:hover {
    color: $brandViolet !important;
  }

  &:last-child {
    margin-right: 0;
  }
}

.modalform {
  margin: 20px 40px;

  &_content {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-img {
      //   height: 50px;
      //   max-width: 51px;
      margin: auto;
    }

    &-qestion {
      font-weight: 400;
      font-size: 16px;
      color: $brandDark;
      text-align: center;
      margin-top: 33px;
    }

    &-nav {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-btn {
      margin: 0 8px;
      align-items: center;
      padding: 8px 24px;
      border: none;
      border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      transition: $transition-base;
    }
  }
}

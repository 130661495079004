.section {
  display: flex;
  flex-direction: column;
  width: 100%;

  &_header {
    background-color: $white;
    padding: 24px 40px;
    border-radius: 8px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-weight: 900;
      font-size: 23px;
      color: $brandDark;
    }

    &-btn {
      border-radius: 8px;
      font-weight: 400;
      color: $brandDark;
      font-size: 16px;
      padding: 8px 24px;
      transition: $transition-base;
      margin-left: 16px;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    background-color: $white;
    margin: 40px 0;
    flex-wrap: wrap;
    border-radius: 8px;
    padding: 20px;
  }
}

.addsection {
  &_form {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 40px 20px;

    &_btn {
      border: none;
      border-radius: 8px;
      padding: 8px 24px;
      font-weight: 500;
      line-height: 16px;
      height: 50px;
      margin: auto 0 auto 10px;
      transition: $transition-base;
    }
  }

  &_span {
    margin-left: 20px;
    font-weight: 500;
    font-size: 16px;
    color: $brandDark;
  }

  &_coupons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 4px;
  }
}

.selected {
  background: rgba(59, 59, 59, 0.3);
  //   filter: brightness(80%);
  position: relative;

  &_img {
    position: absolute;
    left: auto;
    right: 10px;
    top: 10px;
  }
}

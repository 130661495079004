.wallet {
  display: flex;
  width: 100%;
  padding: 30px 0;

  .wallet_item {
    color: $black !important;
  }

  .align_center {
    text-align: center;
  }
}

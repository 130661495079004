.main-section {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 8px;
  padding: 20px;
  // flex-wrap: wrap;
  margin-bottom: 40px;
  width: 100%;

  &_container {
    display: flex;
    flex-wrap: wrap;
  }
}

.loader {
  display: flex;
  margin: 40px auto 0;
}

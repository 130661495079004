.login-form {
  max-width: 60% !important;
  margin: auto;

  &_img {
    display: flex;
    margin: auto;
    margin-bottom: 40px;
  }

  &_title {
    margin-bottom: 16px;
    font-weight: 900;
    font-size: 33px;
    color: $brandDark;
  }

  &_content {
    width: 90%;
    margin: auto;

    &-label {
      font-weight: 500;
      font-size: 16px;
      color: $brandDark;
    }

    &-input {
      border-color: #eeeeee !important;
      padding: 8px 16px;
      border-radius: 4px !important;
      font-size: 16px !important;
      color: $brandDark !important;
      line-height: 31px;
    }

    &-check {
      display: flex;
      justify-content: space-between;
      align-items: center !important;
      margin-bottom: 40px !important;
    }
  }

  &_btn {
    font-weight: 500;
    font-size: 16px;
    background-color: $brandViolet !important;
    padding: 8px 24px !important;
    min-width: 188px !important;
    line-height: 175% !important;
    transition: $transition-base;
    color: $white !important;

    &:hover {
      background-color: $brandVioletHover !important;
    }
  }
}

.login-form_checkbox {
  border-color: $brandDark !important;
  border-radius: 2px !important;
}

.login-form_checkbox:checked {
  background-image: url("../../../images/cards/checkeditem.svg") !important;
}

.item-count {
  background-color: $brandViolet;
  border-radius: 100%;

  color: $white;
  text-align: center;

  &_active {
    background-color: $white;
    color: $brandViolet;
  }
}

.sidebar_link {
  color: $categoriescolor !important;
  border-radius: 8px !important;

  &:hover {
    color: $brandViolet !important;
  }

  &_active {
    background-color: $brandViolet !important;
    color: $white !important;

    &:hover {
      color: $white !important;
    }
  }
}

.nav-link {
  font-weight: 500;
  font-size: 16px;
}

.mainlogo {
  padding: 11px 35px;
}

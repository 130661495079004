/*!
* Version: 1.2.0
* Template: Example Project
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for Template.
*
*/

// Configuration
@import "./bootstrap/functions";
// Variables
@import "./hope-ui-design-system/variable";
@import "./hope-ui-design-system/variables/index";
@import "./bootstrap/variables";

@import "./bootstrap/mixins";

// Hope Ui Design System Mixin And Helper
@import "./hope-ui-design-system/helper/functions";
@import "./hope-ui-design-system/helper/mixins";
@import "./hope-ui-design-system/helper/reboot";

@import "./custom/auth/authentication";
@import "./custom/kanban/kanban";
@import "./custom/pricing/pricing";
@import "./custom/ui-kit/ui-kit";

// THRIVE
@import "./custom/header/header";
@import "./custom/footer/footer";
@import "./custom/subheader/subheader";
@import "./custom/sidebar/sidebar";
@import "./custom/main/main";
@import "./custom/coupons/coupons";
@import "./custom/customers/customers";
@import "./custom/loginform/loginform";
@import "./custom/categories/categories";
@import "./custom/wallet/wallet";
@import "./custom/modalform/modalform";
@import "./custom/sections/sections";
@import "./custom/brands/brands";

@import "./custom/recommended/recommended";

.customers {
  margin: 16px;
  border: 1px solid #eeeeee;
  border-bottom: none;
  border-radius: 8px;

  &_table {
    border-radius: 8px;
  }

  &_name {
    font-weight: 500;
    font-size: 16px;
    color: $brandDark;
  }

  &_phone,
  &_email {
    font-weight: 400;
    font-size: 16px;
    color: $brandDark !important;
  }

  &_btn {
    padding-left: 0 !important;
    border: none !important;
    background: transparent !important;
  }

  &_info {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 48px 24px !important;

    &-title {
      font-weight: 500;
      font-size: 23px;
      color: #232d42;
    }

    &-form {
      display: flex;
      justify-content: space-between;
      margin-top: 48px;
      width: 100%;

      &_item-text {
        font-weight: 500;
        font-size: 13px;
        color: $brandDark !important;
      }
    }

    &-img {
      display: flex;
      max-width: 381px;
      height: 211px;

      &-item {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &-nav {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 48px;
    }
    &-decision{
      display: flex;

    }

    &-btn {
      padding: 16px 32px;
      border: none;
      font-weight: 500;
      font-size: 19px;
      border-radius: 8px;
      margin-right: 16px;
      min-width: 208px;
      transition: $transition-base;

      &:last-child {
        margin-right: 0;
      }
    }

    .approve {
      background-color: $brandViolet;
      color: $white;

      &:hover {
        background-color: $brandVioletHover;
      }
    }

    .reject {
      background-color: transparent;
      border: 1px solid $brandDark;
      color: $brandDark;

      &:hover {
        border-color: $brandViolet;
        color: $brandViolet;
      }
    }
  }
}

.header-title {
  display: flex;
  width: 100%;
  margin: 16px 16px 0 16px;
  justify-content: space-between;

  &_item {
    display: flex;
    max-width: 200px !important;
    align-items: center !important;

    justify-content: space-between;

    &-btn {
      background-color: transparent !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #ebeefd !important;
      padding: 8px !important;
      color: $brandDark !important;
      width: 70px !important;
      margin: 0 8px;
      height: 36px;
      border-radius: 8px;
      overflow: hidden;
    }

    &-span {
      font-weight: 400;
      font-size: 16px !important;
      color: $brandDark !important;
    }
  }

  &_input {
    max-width: 30%;
    height: 50px;
  }
}

.customers_th {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #8a92a6 !important;
  text-transform: unset !important;
}

.status {
  padding: 5px 16px;
  border-radius: 8px;
}

.statusNew {
  background-color: $statusNewbg;
  color: $statusNew;
}

.statusVerified {
  background-color: $statusVerifiedbg;
  color: $statusVerified;
}

.statusUnverified {
  background-color: $statusUnverifiedbg;
  color: $statusUnverified;
}

.statusNeedDocument {
  background-color: $statusNeedDocumentbg;
  color: $statusNeedDocument;
}

.statusWaitingVerify {
  background-color: $statusWaitingVerifybg;
  color: $statusWaitingVerify;
}

.dropdown-toggle::after {
  display: none !important;
}

.customers_footer {
  margin: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.active_page {
  background-color: $brandViolet !important;
  color: $white !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.pagination {
  margin: auto 16px auto auto !important;
}

.statuses_select {
  width: fit-content !important;
  height: fit-content !important;
}

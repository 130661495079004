@import "../../bootstrap/variables";

.subheader {
  display: flex;
  position: relative;
  background-color: $white;
  margin: 50px auto 0;
  border-radius: 8px;
  width: 100%;
  justify-content: space-between;

  &_title {
    margin: auto 0 auto 40px;
    color: $brandDark;
    font-style: normal;
    font-weight: 900;
    font-size: 23px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &_btn {
    background-color: $brandViolet;
    border: none;
    font-weight: 400;
    font-size: 16px;
    border-radius: 8px;
    color: $white;
    padding: 8px 24px;
    height: 50%;
    margin: auto 40px;
    cursor: pointer;
    transition: $transition-base;

    &:hover {
      background-color: $brandVioletHover;
    }
  }
}
